.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  align-items: center;
  justify-content: center;
}
.footer-container1 {
  width: 1172px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container2 {
  flex: 0 0 auto;
  width: auto;
  height: 191px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-image {
  width: 420px;
  height: 146px;
  object-fit: cover;
}
.footer-image1 {
  width: 104px;
  height: 50%;
  object-fit: cover;
}
.footer-image2 {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
.footer-root-class-name {
  background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
}
@media(max-width: 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container1 {
    margin-bottom: var(--dl-space-space-doubleunit);
    height: 200px;
    width: auto;
  }
}
@media(max-width: 479px) {
  .footer-image {
    width: 400px;
    height: auto;
  }
}
