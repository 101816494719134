.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  font-family: ""Luckiest Guy", Sans-serif";
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.home-container01 {
  width: 100%;
  height: 987px;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0,0%,100%,.8);
}
.home-text {
  text-align: center;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text01 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.home-image {
  width: 292px;
  align-self: center;
  object-fit: cover;
}
.home-text02 {
  color: var(--dl-color-secondary-600);
  align-self: center;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}
.home-container02 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container03 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-image1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: auto;
  margin: auto;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}
.home-testimonials {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/one-piece-wallpaper-1500w.jpg");
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: 15rem;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: 15rem;
  justify-content: space-between;
}
.home-container05 {
  width: 624px;
  height: auto;
  display: flex;
  position: relative;
  align-items: space-between;
  flex-direction: column;
}
.home-container06 {
  width: 785px;
  height: 867px;
  display: flex;
  max-width: 600px;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: 79px;
  flex-direction: column;
  background-image: radial-gradient(circle at left, rgb(254, 172, 94) 0.00%,rgb(199, 164, 118) 54.00%,rgb(199, 121, 208) 50.00%,rgb(199, 164, 118) 51.00%);
}
.home-text03 {
  color: rgba(0, 0, 0, 0.73);
  width: 599px;
  height: 81px;
  font-size: 42px;
  align-self: flex-start;
  text-align: center;
  line-height: 2;
  text-transform: capitalize;
  background-color: #b5915c;
}
.home-image2 {
  width: 335px;
  height: 457px;
  align-self: center;
  object-fit: cover;
}
.home-text06 {
  color: rgb(57, 31, 31);
  height: 124px;
  opacity: 0.8;
  font-size: 30px;
  margin-bottom: var(--dl-space-space-doubleunit);
  background-color: rgb(203, 185, 149);
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: 108px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-link1 {
  display: contents;
}
.home-image3 {
  width: auto;
  height: 108px;
  object-fit: cover;
  text-decoration: none;
}
.home-link2 {
  display: contents;
}
.home-image4 {
  width: auto;
  height: 108px;
  object-fit: cover;
  text-decoration: none;
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 191px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-logos {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-bottom-wave-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -5px;
  position: absolute;
  object-fit: cover;
}
.home-image5 {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-container01 {
    max-width: 960px;
  }
  .home-card {
    width: 100%;
  }
  .home-container04 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    justify-content: center;
  }
  .home-container05 {
    align-items: center;
  }
  .home-container06 {
    margin-right: 0px;
  }
  .home-logos {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-hero {
    background-size: cover;
    background-image: url("/4-1500w.webp");
  }
  .home-card {
    align-items: center;
  }
  .home-text02 {
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-image1 {
    display: none;
  }
  .home-container04 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .home-image2 {
    width: 50%;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .home-card {
    width: 398px;
    height: 698px;
    padding: var(--dl-space-space-unit);
  }
  .home-container02 {
    align-items: center;
    flex-direction: column;
  }
  .home-container03 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container04 {
    max-width: auto;
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-container05 {
    width: auto;
  }
  .home-container06 {
    width: auto;
    height: auto;
    max-width: auto;
  }
  .home-text03 {
    width: auto;
    height: auto;
    align-self: center;
    line-height: 1.3;
  }
  .home-image2 {
    width: 314px;
    height: 492px;
  }
  .home-text06 {
    height: auto;
  }
}
