.roadmap-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.roadmap-blog-posts {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.roadmap-text {
  color: var(--dl-color-secondary-500);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.roadmap-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.roadmap-container2 {
  flex: 1;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.roadmap-card {
  flex: 1;
  width: 255px;
  display: flex;
  padding: var(--dl-space-space-unitandahalfunit);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  background-size: cover;
  background-image: url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/seaside.jpg");
}
.roadmap-container3 {
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-direction: column;
}
.roadmap-text3 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.roadmap-text4 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.roadmap-text5 {
  display: inline;
  font-weight: 400;
  white-space: normal;
}
.roadmap-container4 {
  display: flex;
  position: relative;
}
.roadmap-button {
  color: var(--dl-color-blue-500);
  display: flex;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.roadmap-text6 {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-halfunit);
}
.roadmap-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.roadmap-container5 {
  top: auto;
  flex: 0 0 auto;
  left: 0px;
  right: auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  background-color: rgba(0,0,0,.4);
}
@media(max-width: 991px) {
  .roadmap-container2 {
    width: 100%;
  }
  .roadmap-card {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .roadmap-blog-posts {
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .roadmap-container2 {
    margin-top: var(--dl-space-space-doubleunit);
  }
}
